<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Campaigns</div>
          <!--<span class="grey&#45;&#45;text">Filtrér rapporten efter behov. Som standard vises nuværende måned.</span>-->
        </div>
        <v-spacer></v-spacer>
        <!--Larger than mobile filter button-->
        <v-tooltip v-if="$vuetify.breakpoint.smAndUp" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges" :value="filtersCount">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="openFilterModal"
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filtrer</span>
        </v-tooltip>
        <!--Mobile button-->
        <v-btn
          color="primary"
          class="hidden-sm-and-up"
          fixed
          bottom
          right
          fab
          @click="showFiltersDialog = true"
        >
          <v-icon>fas fa-filter</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <campaigns-data-table
            :isInfluencer="false"
            :items="campaigns"
            :loading="loading"
            :page="page"
            :total="total"
            @changePage="getCampaigns($event)"
          ></campaigns-data-table>
        </div>
      </v-card-text>
    </v-card>
    <find-campaign-filters-dialog
      v-model="showFiltersDialog"
      :filters="filters"
      @save="filters = $event"
      :businessAutocomelete="true"
    >
    </find-campaign-filters-dialog>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import CampaignsDataTable from "../../data-tables/CampaignsDataTable";
import FindCampaignFiltersDialog from "../../influencer/dialogs/FindCampaignFiltersDialog";
import { mapActions } from "vuex";

export default {
  components: { CampaignsDataTable, FindCampaignFiltersDialog },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    campaigns: [],
    loading: true,
    limit: 15,
    page: 1,
    total: null,
    hasMore: true,
    showFiltersDialog: false,
    filtersTmp: null,
    filters: {},
    filtersUpdateTimeout: null
  }),
  computed: {
    filtersCount() {
      let filters = this.cloneDeep(this.filters);
      let count = Object.keys(filters).length;
      // If search key is set but has no value. Then subtract 1 from count
      if ("search" in filters && !filters.search) {
        count--;
      }
      return count;
    }
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this.filtersUpdateTimeout);
        this.filtersUpdateTimeout = setTimeout(() => {
          this.campaigns = [];
          this.hasMore = true;
          this.items = [];
          this.getCampaigns();
          this.setUrlSearchFilters(this.filters);
        }, 500);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["loadCampaignsSummary"]),

    getCampaigns(page = 1) {
      this.page = page;
      this.loading = true;
      this.campaigns = [];
      let filters = this.cloneDeep(this.filters);

      if (filters.category_ids) {
        filters.category_ids = filters.category_ids.map(id => {
          return Number(id);
        });
      }
      if (!filters.business_uuid) {
        delete filters["business_uuid"];
      }
      let params = {
        ...filters,
        draft: false,
        page: this.page,
        limit: this.limit,
        with: ["influencers"]
      };

      this.loadCampaignsSummary(params)
        .then(campaigns => {
          // If length is not equal to limit there's no more campaigns
          if (campaigns.total !== params.limit) {
            this.hasMore = false;
          }
          for (let index in campaigns.response) {
            this.campaigns.push(campaigns.response[index]);
          }
          this.total = campaigns.lastPage;
          this.loading = false;
        })
        .catch(() => {
          this.setSnackError("Fejl");
        });
    },
    openFilterModal() {
      this.filtersTmp = this.cloneDeep(this.filters);
      this.showFiltersDialog = true;
    }
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      this.filters = this.getUrlSearchFilters();
    } else {
      this.getCampaigns();
    }
  }
};
</script>
